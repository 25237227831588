/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import imageCompression from "browser-image-compression";
import { PiCheckBold } from "react-icons/pi";
import { useEffect, useState } from "react";
import { IoChevronBackSharp, IoLocate } from "react-icons/io5";
import { BiLocationPlus, BiUpload } from "react-icons/bi";
import TabPane from "antd/es/tabs/TabPane";
import { GrLocation } from "react-icons/gr";
import { MdOutlineBlock, MdOutlineErrorOutline } from "react-icons/md";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EyeTwoTone,
  LinkOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { RiTeamLine } from "react-icons/ri";
import {
  parsePhoneNumberFromString,
  parsePhoneNumber,
} from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import dayjs from "dayjs";
import { RiZzzFill } from "react-icons/ri";
import axios from "axios";
import { decryptPassword } from "../../Common/CommonFuntions";
import { AiOutlineEdit } from "react-icons/ai";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { TeamSVG } from "../../Common/SettingSidebarIconsSvg";
import { getInitials } from "../../Common/ReturnColumnValue";
const { Option } = Select;

export const CreateNewClinicModal = ({
  iscrearteNewClinicModalVisible,
  setiscrearteNewClinicModalVisible,
  openNotificationWithIcon,
  buttonLoader,
  setbuttonLoader,
  getAllClinicDetails,
}) => {
  const [crearteNewClinicModalForm] = Form.useForm();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [services, setservices] = useState([]);
  const [currentValue, setCurrentValue] = useState("");
  const [websiteList, setwebsiteList] = useState([]);
  const [clinicLogo, setclinicLogo] = useState();
  const [clinicFavicon, setclinicFavicon] = useState();

  const [createNewClinicActiveTabKey, setcreateNewClinicActiveTabKey] =
    useState("1");

  const [mondayFrom, setMondayFrom] = useState();
  const [mondayTo, setMondayTo] = useState();
  const [mondayClosed, setMondayClosed] = useState(true);

  const [tuesdayFrom, setTuesdayFrom] = useState();
  const [tuesdayTo, setTuesdayTo] = useState();
  const [tuesdayClosed, setTuesdayClosed] = useState(true);

  const [wednesdayFrom, setWednesdayFrom] = useState();
  const [wednesdayTo, setWednesdayTo] = useState();
  const [wednesdayClosed, setWednesdayClosed] = useState(true);

  const [thursdayFrom, setThursdayFrom] = useState();
  const [thursdayTo, setThursdayTo] = useState();
  const [thursdayClosed, setThursdayClosed] = useState(true);

  const [fridayFrom, setFridayFrom] = useState();
  const [fridayTo, setFridayTo] = useState();
  const [fridayClosed, setFridayClosed] = useState(true);

  const [saturdayFrom, setSaturdayFrom] = useState();
  const [saturdayTo, setSaturdayTo] = useState();
  const [saturdayClosed, setSaturdayClosed] = useState(false);

  const [sundayFrom, setSundayFrom] = useState();
  const [sundayTo, setSundayTo] = useState();
  const [sundayClosed, setSundayClosed] = useState(false);

  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const serviceList = [
    "Full Arch",
    "Dental Implants",
    "General Dentistry",
    "Othodontics",
    "Cosmetic Dentistry",
    "Oral Surgery",
    "Pediatric Dentistry",
  ];

  const clinicSizeOption = [
    {
      value: "1-5 dentists",
      lable: "1-5 dentists",
    },
    {
      value: "6-10 dentists",
      lable: "6-10 dentists",
    },
    {
      value: "11-15 dentists",
      lable: "11-15 dentists",
    },
    {
      value: "16-20 dentists",
      lable: "16-20 dentists",
    },
    {
      value: "21-25 dentists",
      lable: "21-25 dentists",
    },
    {
      value: "25+ dentists",
      lable: "25+ dentists",
    },
  ];

  const patientsAveragePerWeekOption = [
    {
      value: "Less Than 50",
      lable: "Less Than 50",
    },
    {
      value: "51-100",
      lable: "51-100",
    },
    {
      value: "101-150",
      lable: "101-150",
    },
    {
      value: "More Than 150",
      lable: "More Than 150",
    },
  ];

  const handleAddressSelect = (address, data) => {
    setSelectedAddress({ key: data?.key, address: address?.trim() });
  };

  const removeAddress = (websiteIndex, addressIndex) => {
    setwebsiteList((prevList) => {
      return prevList.map((website, i) => {
        if (i === websiteIndex) {
          return {
            ...website,
            addressList: website.addressList.filter(
              (_, j) => j !== addressIndex
            ),
          };
        }
        return website;
      });
    });
  };

  const addAddressToList = (websiteIndex) => {
    if (selectedAddress) {
      setwebsiteList((prevList) =>
        prevList.map((website, i) => {
          if (i === websiteIndex) {
            const isDuplicate = website.addressList.some(
              (addr) => addr.address === selectedAddress?.address
            );

            if (!isDuplicate) {
              return {
                ...website,
                addressList: [...website.addressList, selectedAddress],
              };
            } else {
              openNotificationWithIcon(
                "error",
                "Clinic",
                "Duplicate address is not allowed"
              );
            }
          }
          return website;
        })
      );
    }
  };

  const handleTabChange = (key) => {
    setcreateNewClinicActiveTabKey(key);
  };

  const addServices = (val) => {
    if (services?.includes(val?.trim())) {
      setservices(services?.filter((service) => service !== val));
    } else {
      setservices([...services, val]);
    }
  };

  const CustomButton = ({ service, services, onClick }) => {
    const isSelected = services?.includes(service);

    return (
      <Button
        className="custom-btn"
        style={{
          color: isSelected ? "#3900DB" : "",
          borderColor: isSelected ? "#3900DB" : "",
          background: isSelected ? "#ECEEFF" : "",
        }}
        onClick={onClick}
      >
        {isSelected ? <PiCheckBold /> : ""}
        {service}
      </Button>
    );
  };

  const isValidUrl = (value) => {
    try {
      // Attempt to create a new URL object
      new URL(value);
      return true; // If no error is thrown, the URL is valid
    } catch (_) {
      return false; // If an error is thrown, the URL is invalid
    }
  };

  const handleKeyPress = (e) => {
    const trimmedValue = currentValue?.trim();

    if (!trimmedValue) return;

    if (isValidUrl(trimmedValue)) {
      const isDuplicate = websiteList.some(
        (item) => item?.website_link === trimmedValue
      );

      if (!isDuplicate) {
        setwebsiteList((prevList) => [
          ...prevList,
          { website_link: trimmedValue, addressList: [] },
        ]);
      } else {
        openNotificationWithIcon(
          "error",
          "Clinic",
          "Duplicate URL is not allowed"
        );
      }

      setCurrentValue(""); // Reset input field
    } else {
      openNotificationWithIcon("error", "Clinic", "Please enter a valid URL");
    }
  };

  const getLogoUrl = (url) => {
    const domain = new URL(url)?.hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=64`;
  };

  const handleRemoveValue = (index) => {
    setwebsiteList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleClinicLogoChange = async (file) => {
    const maxSizeInMB = 1;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    try {
      let fileToUpload = file;

      if (file.size > maxSizeInBytes) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };

        fileToUpload = await imageCompression(file, options);
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setclinicLogo(e.target.result);
      };
      reader.readAsDataURL(fileToUpload);
    } catch (error) {
      console.error("Image compression failed:", error);
      alert("Image compression failed. Please try again with a smaller file.");
    }
  };

  const handleClinicLogoDelete = () => {
    setclinicLogo();
  };

  const handleClinicFaviconChange = async (file) => {
    const maxSizeInMB = 1;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    try {
      let fileToUpload = file;

      if (file.size > maxSizeInBytes) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };

        fileToUpload = await imageCompression(file, options);
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setclinicFavicon(e.target.result);
      };
      reader.readAsDataURL(fileToUpload);
    } catch (error) {
      console.error("Image compression failed:", error);
      alert("Image compression failed. Please try again with a smaller file.");
    }
  };

  const handleFaviconDelete = () => {
    setclinicFavicon();
  };

  const handleCreateNewClinic = async (values) => {
    setbuttonLoader(true);

    let data = {
      dentist_full_name: values?.dentist_full_name,
      clinic_name: values?.clinic_name,
      clinic_phone_number: values?.clinic_phone_number?.startsWith("+")
        ? values.clinic_phone_number
        : `+${values?.clinic_phone_number}`,
      email: values?.email,
      password: values?.password,
      clinic_logo: clinicLogo,
      clinic_favicon: clinicFavicon,
      clinic_addresses: values?.clinic_addresses,
      // clinic_address_state: values?.clinic_address_state,
      // clinic_address_zip_code: values?.clinic_address_zip_code,
      // clinic_address_city: values?.clinic_address_city,
      // clinic_address_street: values?.clinic_address_street,
      // clinic_address_country: values?.clinic_address_country,

      monday_from: mondayFrom ? mondayFrom : undefined,
      monday_to: mondayTo ? mondayTo : undefined,
      monday_closed: mondayClosed,
      tuesday_from: tuesdayFrom ? tuesdayFrom : undefined,
      tuesday_to: tuesdayTo ? tuesdayTo : undefined,
      tuesday_closed: tuesdayClosed,
      wednesday_from: wednesdayFrom ? wednesdayFrom : undefined,
      wednesday_to: wednesdayTo ? wednesdayTo : undefined,
      wednesday_closed: wednesdayClosed,
      thursday_from: thursdayFrom ? thursdayFrom : undefined,
      thursday_to: thursdayTo ? thursdayTo : undefined,
      thursday_closed: thursdayClosed,
      friday_from: fridayFrom ? fridayFrom : undefined,
      friday_to: fridayTo ? fridayTo : undefined,
      friday_closed: fridayClosed,
      saturday_from: saturdayFrom ? saturdayFrom : undefined,
      saturday_to: saturdayTo ? saturdayTo : undefined,
      saturday_closed: saturdayClosed,
      sunday_from: sundayFrom ? sundayFrom : undefined,
      sunday_to: sundayTo ? sundayTo : undefined,
      sunday_closed: sundayClosed,

      smtp_host: values?.smtp_host || undefined,
      smtp_email: values?.smtp_email || undefined,
      smtp_password: values?.smtp_password || undefined,

      clinic_size: values?.clinic_size,
      patients_average_per_week: values?.patients_average_per_week,
      in_house_arch_lab_yn: values?.in_house_arch_lab_yn,
      arch_digital_workflow_yn: values?.arch_digital_workflow_yn,
      services_frequently: values?.services_frequently,

      clinic_dialer_number: values?.clinic_dialer_number?.startsWith("+")
        ? values.clinic_dialer_number
        : `+${values?.clinic_dialer_number}`,

      clinic_website: websiteList,
    };

    const token = localStorage.getItem("authToken");
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/createClinic`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Clinic added successfully"
        );
        setiscrearteNewClinicModalVisible(false);
        setbuttonLoader(false);
        getAllClinicDetails();
        setwebsiteList([]);
        setcreateNewClinicActiveTabKey("1");
        crearteNewClinicModalForm?.resetFields();
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        setbuttonLoader(false);
        openNotificationWithIcon(
          "error",
          "Error",
          err?.response?.data?.message || err?.message
        );
      });
  };

  return (
    <Modal
      style={{ top: 20 }}
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            icon={<IoChevronBackSharp />}
            onClick={() => {
              setcreateNewClinicActiveTabKey(1);
              crearteNewClinicModalForm?.resetFields();
              setiscrearteNewClinicModalVisible(false);
            }}
          ></Button>

          <Typography style={{ marginLeft: 10 }}>Create New Clinic</Typography>
        </div>
      }
      visible={iscrearteNewClinicModalVisible}
      footer={
        <>
          <Divider style={{ margin: 0 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 10,
            }}
          >
            <Space>
              <Button
                onClick={() => {
                  crearteNewClinicModalForm?.resetFields();
                  setcreateNewClinicActiveTabKey(1);
                  setiscrearteNewClinicModalVisible(false);
                }}
              >
                Cancel
              </Button>

              {createNewClinicActiveTabKey === 5 ? (
                <Button
                  className="custom-primary-button"
                  loading={buttonLoader}
                  onClick={() => {
                    if (websiteList?.length <= 0) {
                      openNotificationWithIcon(
                        "error",
                        "Error",
                        "Please add atleast one website"
                      );
                    } else {
                      crearteNewClinicModalForm?.submit();
                    }
                  }}
                >
                  Create
                </Button>
              ) : (
                <Button
                  className="custom-primary-button"
                  loading={buttonLoader}
                  onClick={async () => {
                    if (createNewClinicActiveTabKey === "1") {
                      crearteNewClinicModalForm
                        .validateFields()
                        .then(async () => {
                          setcreateNewClinicActiveTabKey(
                            parseInt(createNewClinicActiveTabKey) + 1
                          );
                        })
                        .catch((errorInfo) => {
                          console.log(errorInfo);
                        });
                    } else if (createNewClinicActiveTabKey === 3) {
                      crearteNewClinicModalForm
                        .validateFields()
                        .then(() => {
                          setcreateNewClinicActiveTabKey(
                            parseInt(createNewClinicActiveTabKey) + 1
                          );
                        })
                        .catch((errorInfo) => {
                          console.log(errorInfo);
                        });
                    } else {
                      crearteNewClinicModalForm
                        .validateFields()
                        .then(() => {
                          setcreateNewClinicActiveTabKey(
                            parseInt(createNewClinicActiveTabKey) + 1
                          );
                        })
                        .catch((errorInfo) => {
                          console.log(errorInfo);
                        });
                    }
                  }}
                >
                  Next
                </Button>
              )}
            </Space>
          </div>
        </>
      }
      closable={false}
      width={800}
      className="custom-modal"
    >
      <Form
        name="signup"
        form={crearteNewClinicModalForm}
        initialValues={{ remember: true }}
        onFinish={handleCreateNewClinic}
        layout="vertical"
        style={{ height: "73vh", overflow: "auto" }}
      >
        <Tabs
          activeKey={createNewClinicActiveTabKey?.toString()}
          onChange={handleTabChange}
          items={[
            {
              label: "Account",
              key: "1",
              children: (
                <div style={{ padding: 10 }}>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={12}>
                      <Typography>Dentist Full Name</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="dentist_full_name"
                        label={
                          <Typography className="custom-text1">Name</Typography>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter dentist full name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please enter dentist full name"
                          className="custom-text1"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={12}>
                      <Typography>Clinic Name</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="clinic_name"
                        label={
                          <Typography className="custom-text1">Name</Typography>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter clinic name!",
                          },
                        ]}
                      >
                        <Input
                          className="custom-text1"
                          placeholder="Please enter clinic name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={12}>
                      <Typography>Clinic Phone Number</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <Typography className="custom-text1">
                            Clinic Phone Number
                          </Typography>
                        }
                        required
                      >
                        <Input.Group compact>
                          <Form.Item
                            style={{ width: "100%" }}
                            name="clinic_phone_number"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: "Please enter phone number !",
                              },
                            ]}
                          >
                            <PhoneInput
                              inputStyle={{ width: "100%" }}
                              country={"us"}
                              value={""}
                              onChange={(phone) => {
                                console.log(phone);
                              }}
                            />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={12}>
                      <Typography>Clinic Email</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="email"
                        label={
                          <Typography className="custom-text1">
                            Email
                          </Typography>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter valid email!",
                            type: "email",
                          },
                        ]}
                      >
                        <Input
                          className="custom-text1"
                          placeholder="Please enter email"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={12}>
                      <Typography>Clinic Password</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="password"
                        label={
                          <Typography className="custom-text1">
                            Password
                          </Typography>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter password!",
                          },
                        ]}
                      >
                        <Input.Password
                          className="custom-text1"
                          placeholder="Please enter password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={12}>
                      <Typography>Clinic Logo</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="clinic_logo">
                        <Space>
                          {clinicLogo ? (
                            <Image
                              size={50}
                              style={{
                                width: 200,
                                height: 80,
                                objectFit: "contain",
                              }}
                              src={clinicLogo}
                            />
                          ) : (
                            ""
                          )}
                          {!clinicLogo ? (
                            <Upload
                              accept="image/*"
                              showUploadList={false}
                              beforeUpload={(file) => {
                                handleClinicLogoChange(file);
                                return false;
                              }}
                              style={{ width: "100%" }}
                            >
                              <Button
                                icon={<BiUpload />}
                                style={{ marginTop: 8 }}
                              >
                                Upload Clinic Logo
                              </Button>
                            </Upload>
                          ) : (
                            ""
                          )}
                          {clinicLogo ? (
                            <Button
                              type="link"
                              danger
                              onClick={handleClinicLogoDelete}
                            >
                              Delete
                            </Button>
                          ) : (
                            ""
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={12}>
                      <Typography>Clinic Favicon</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="clinic_favicon">
                        <Space>
                          {clinicFavicon ? (
                            <Avatar
                              size={50}
                              src={clinicFavicon}
                              shape="square"
                            />
                          ) : (
                            ""
                          )}

                          {!clinicFavicon ? (
                            <Upload
                              accept="image/*"
                              showUploadList={false}
                              beforeUpload={(file) => {
                                handleClinicFaviconChange(file);
                                return false; // Prevent auto-upload
                              }}
                            >
                              <Button
                                icon={<BiUpload />}
                                style={{ marginTop: 8 }}
                              >
                                Upload Clinic Favicon
                              </Button>
                            </Upload>
                          ) : (
                            ""
                          )}
                          {clinicFavicon ? (
                            <Button
                              type="link"
                              danger
                              onClick={handleFaviconDelete}
                            >
                              Delete
                            </Button>
                          ) : (
                            ""
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              label: "Clinic Details",
              key: "2",
              children: (
                <div style={{ padding: 10 }}>
                  <Row style={{ padding: "10px 0px 10px 0px" }}>
                    <Col span={8}>
                      <Typography>Clinic Address</Typography>
                    </Col>

                    <Col span={16}>
                      <Form.List name="clinic_addresses">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Row
                                key={key}
                                gutter={[10, 0]}
                                style={{ marginBottom: 10 }}
                              >
                                <Col span={12}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "clinic_address_state"]}
                                    label={
                                      <Typography className="custom-text1">
                                        State
                                      </Typography>
                                    }
                                  >
                                    <Input
                                      placeholder="Enter state"
                                      className="custom-text1"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "clinic_address_zip_code"]}
                                    label={
                                      <Typography className="custom-text1">
                                        ZIP Code
                                      </Typography>
                                    }
                                  >
                                    <Input
                                      placeholder="Enter ZIP Code"
                                      className="custom-text1"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "clinic_address_city"]}
                                    label={
                                      <Typography className="custom-text1">
                                        City
                                      </Typography>
                                    }
                                  >
                                    <Input
                                      placeholder="Enter clinic city"
                                      className="custom-text1"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "clinic_address_street"]}
                                    label={
                                      <Typography className="custom-text1">
                                        Street
                                      </Typography>
                                    }
                                  >
                                    <Input
                                      placeholder="Enter clinic street"
                                      className="custom-text1"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "clinic_address_country"]}
                                    label={
                                      <Typography className="custom-text1">
                                        Country
                                      </Typography>
                                    }
                                  >
                                    <Input
                                      placeholder="Enter country"
                                      className="custom-text1"
                                    />
                                  </Form.Item>
                                </Col>
                                {fields.length > 1 && (
                                  <Col
                                    span={12}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      type="dashed"
                                      danger
                                      onClick={() => remove(name)}
                                      icon={<MinusCircleOutlined />}
                                    >
                                      Remove
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                Add Address
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      {/* <Row gutter={[10, 0]}>
                        <Col span={12}>
                          <Form.Item
                            name="clinic_address_state"
                            label={
                              <Typography className="custom-text1">
                                State
                              </Typography>
                            }
                          >
                            <Input
                              placeholder="Enter state"
                              className="custom-text1"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="clinic_address_zip_code"
                            label={
                              <Typography className="custom-text1">
                                ZIP Code
                              </Typography>
                            }
                          >
                            <Input
                              placeholder="Enter ZIP Code"
                              className="custom-text1"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="clinic_address_city"
                            label={
                              <Typography className="custom-text1">
                                City
                              </Typography>
                            }
                          >
                            <Input
                              placeholder="Enter clinic city"
                              className="custom-text1"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="clinic_address_street"
                            label={
                              <Typography className="custom-text1">
                                Street
                              </Typography>
                            }
                          >
                            <Input
                              placeholder="Enter clinic street"
                              className="custom-text1"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="clinic_address_country"
                            label={
                              <Typography className="custom-text1">
                                Country
                              </Typography>
                            }
                          >
                            <Input
                              placeholder="Enter country"
                              className="custom-text1"
                            />
                          </Form.Item>
                        </Col>
                      </Row> */}
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={8}>
                      <Typography> Consulting Hours</Typography>
                    </Col>
                    <Col span={16}>
                      <Row
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          justifyContent: "space-between",
                          padding: "5px 0px",
                        }}
                      >
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Switch
                              checked={mondayClosed}
                              onChange={(e) => {
                                setMondayClosed(e);
                              }}
                            />
                            <Typography style={{ marginLeft: "10px" }}>
                              Monday
                            </Typography>
                          </div>
                          {mondayClosed ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <>
                                <Typography style={{ marginRight: "5px" }}>
                                  From
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    mondayFrom
                                      ? dayjs(mondayFrom, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setMondayFrom(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setMondayFrom(null);
                                    }
                                  }}
                                />

                                <Typography style={{ margin: "0px 5px" }}>
                                  To
                                </Typography>
                                <TimePicker
                                  value={
                                    mondayTo ? dayjs(mondayTo, "hh:mm A") : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setMondayTo(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setMondayTo(null);
                                    }
                                  }}
                                  format={"hh:mm A"}
                                />
                              </>
                            </div>
                          ) : (
                            <Button
                              disabled
                              icon={<RiZzzFill />}
                              style={{ width: "100%", maxWidth: 250 }}
                            >
                              Closed
                            </Button>
                          )}
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "10px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Switch
                              checked={tuesdayClosed}
                              onChange={(e) => {
                                setTuesdayClosed(e);
                              }}
                            />
                            <Typography style={{ marginLeft: "10px" }}>
                              Tuesday
                            </Typography>
                          </div>

                          {tuesdayClosed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography style={{ marginRight: "5px" }}>
                                  From
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    tuesdayFrom
                                      ? dayjs(tuesdayFrom, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setTuesdayFrom(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setTuesdayFrom(null);
                                    }
                                  }}
                                />
                                <Typography style={{ margin: "0px 5px" }}>
                                  To
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    tuesdayTo
                                      ? dayjs(tuesdayTo, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setTuesdayTo(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setTuesdayTo(null);
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                disabled
                                icon={<RiZzzFill />}
                                style={{ width: "100%", maxWidth: 250 }}
                              >
                                Closed
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "10px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Switch
                              checked={wednesdayClosed}
                              onChange={(e) => {
                                setWednesdayClosed(e);
                              }}
                            />
                            <Typography style={{ marginLeft: "10px" }}>
                              Wednesday
                            </Typography>
                          </div>
                          {wednesdayClosed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography style={{ marginRight: "5px" }}>
                                  From
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    wednesdayFrom
                                      ? dayjs(wednesdayFrom, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setWednesdayFrom(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setWednesdayFrom(null);
                                    }
                                  }}
                                />
                                <Typography style={{ margin: "0px 5px" }}>
                                  To
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    wednesdayTo
                                      ? dayjs(wednesdayTo, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setWednesdayTo(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setWednesdayTo(null);
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                disabled
                                icon={<RiZzzFill />}
                                style={{ width: "100%", maxWidth: 250 }}
                              >
                                Closed
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "10px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Switch
                              checked={thursdayClosed}
                              onChange={(e) => {
                                setThursdayClosed(e);
                              }}
                            />
                            <Typography style={{ marginLeft: "10px" }}>
                              Thursday
                            </Typography>
                          </div>
                          {thursdayClosed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography style={{ marginRight: "5px" }}>
                                  From
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    thursdayFrom
                                      ? dayjs(thursdayFrom, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setThursdayFrom(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setThursdayFrom(null);
                                    }
                                  }}
                                />
                                <Typography style={{ margin: "0px 5px" }}>
                                  To
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    thursdayTo
                                      ? dayjs(thursdayTo, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setThursdayTo(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setThursdayTo(null);
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                disabled
                                icon={<RiZzzFill />}
                                style={{ width: "100%", maxWidth: 250 }}
                              >
                                Closed
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "10px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Switch
                              checked={fridayClosed}
                              onChange={(e) => {
                                setFridayClosed(e);
                              }}
                            />
                            <Typography style={{ marginLeft: "10px" }}>
                              Friday
                            </Typography>
                          </div>
                          {fridayClosed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography style={{ marginRight: "5px" }}>
                                  From
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    fridayFrom
                                      ? dayjs(fridayFrom, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setFridayFrom(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setFridayFrom(null);
                                    }
                                  }}
                                />
                                <Typography style={{ margin: "0px 5px" }}>
                                  To
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    fridayTo ? dayjs(fridayTo, "hh:mm A") : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setFridayTo(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setFridayTo(null);
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                disabled
                                icon={<RiZzzFill />}
                                style={{ width: "100%", maxWidth: 250 }}
                              >
                                Closed
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "10px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Switch
                              checked={saturdayClosed}
                              onChange={(e) => {
                                setSaturdayClosed(e);
                              }}
                            />
                            <Typography style={{ marginLeft: "10px" }}>
                              Saturday
                            </Typography>
                          </div>
                          {saturdayClosed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography style={{ marginRight: "5px" }}>
                                  From
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    saturdayFrom
                                      ? dayjs(saturdayFrom, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setSaturdayFrom(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setSaturdayFrom(null);
                                    }
                                  }}
                                />
                                <Typography style={{ margin: "0px 5px" }}>
                                  To
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    saturdayTo
                                      ? dayjs(saturdayTo, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setSaturdayTo(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setSaturdayTo(null);
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                disabled
                                icon={<RiZzzFill />}
                                style={{ width: "100%", maxWidth: 250 }}
                              >
                                Closed
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "10px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Switch
                              checked={sundayClosed}
                              onChange={(e) => {
                                setSundayClosed(e);
                              }}
                            />
                            <Typography style={{ marginLeft: "10px" }}>
                              Sunday
                            </Typography>
                          </div>
                          {sundayClosed ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography style={{ marginRight: "5px" }}>
                                  From
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    sundayFrom
                                      ? dayjs(sundayFrom, "hh:mm A")
                                      : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setSundayFrom(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setSundayFrom(null);
                                    }
                                  }}
                                />
                                <Typography style={{ margin: "0px 5px" }}>
                                  To
                                </Typography>
                                <TimePicker
                                  format={"hh:mm A"}
                                  value={
                                    sundayTo ? dayjs(sundayTo, "hh:mm A") : null
                                  }
                                  onChange={(time) => {
                                    if (time) {
                                      setSundayTo(
                                        dayjs(time).format("hh:mm A")
                                      );
                                    } else {
                                      setSundayTo(null);
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                disabled
                                icon={<RiZzzFill />}
                                style={{ width: "100%", maxWidth: 250 }}
                              >
                                Closed
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={8}>
                      <Typography>Clinic size</Typography>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="clinic_size"
                        label={
                          <Typography className="custom-text1">
                            What is clinic size?
                          </Typography>
                        }
                      >
                        <Select
                          showSearch
                          placeholder="Select a clinic size"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={clinicSizeOption}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={8}>
                      <Typography>Average Patients</Typography>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="patients_average_per_week"
                        label={
                          <Typography className="custom-text1">
                            How many patients do you see on average per week?
                          </Typography>
                        }
                      >
                        <Select
                          showSearch
                          placeholder="Select a person"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={patientsAveragePerWeekOption}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={8}>
                      <Typography>Dental Services</Typography>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="services_frequently">
                        {serviceList.map((service) => (
                          <CustomButton
                            key={service}
                            service={service}
                            services={services}
                            onClick={() => addServices(service)}
                          />
                        ))}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={8}>
                      <Typography>In-house full arch lab?</Typography>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="in_house_arch_lab_yn">
                        <Radio.Group>
                          <Radio value="Y" type="primary">
                            Yes
                          </Radio>
                          <Radio value="N">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <Col span={8}>
                      <Typography>Full arch digital workflow?</Typography>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="arch_digital_workflow_yn">
                        <Radio.Group>
                          <Radio value="Y" type="primary">
                            Yes
                          </Radio>
                          <Radio value="N">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              label: "Dialer",
              key: "3",
              children: (
                <div style={{ padding: 10 }}>
                  <Row
                    gutter={[20, 20]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col span={12}>
                      <Typography>Clinic Dialer Number </Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item required>
                        <Input.Group compact>
                          <Form.Item
                            style={{ width: "100%" }}
                            name="clinic_dialer_number"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: "Please enter dialer number !",
                              },
                            ]}
                          >
                            <PhoneInput
                              inputStyle={{ width: "100%" }}
                              country={"us"}
                              value={""}
                              onChange={(phone) => {
                                console.log(phone);
                              }}
                            />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              label: "SMTP Config",
              key: "4",
              children: (
                <div style={{ padding: 10 }}>
                  <Row
                    gutter={[20, 20]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col span={12}>
                      <Typography>SMTP Host</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item required>
                        <Form.Item name="smtp_host" noStyle>
                          <Input
                            className="custom-text1"
                            placeholder="Enter smtp host"
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={[20, 20]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col span={12}>
                      <Typography>SMTP Email</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item required>
                        <Form.Item name="smtp_email" noStyle>
                          <Input
                            className="custom-text1"
                            placeholder="Enter smtp email"
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={[20, 20]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col span={12}>
                      <Typography>SMTP Host</Typography>
                    </Col>
                    <Col span={12}>
                      <Form.Item required>
                        <Input.Password
                          className="custom-text1"
                          placeholder="Please enter smtp password"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ),
            },
            {
              label: "Websites",
              key: "5",
              children: (
                <div style={{ padding: 10 }}>
                  <Typography>Clinic Website</Typography>
                  <Row
                    gutter={[10, 0]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col span={21}>
                      <Input
                        className="custom-text1"
                        placeholder="Please enter clinic website"
                        value={currentValue}
                        onChange={handleInputChange}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col span={3}>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          handleKeyPress();
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {websiteList.map((val, index) => (
                        <Tag
                          key={index}
                          className="custom-text1"
                          style={{
                            padding: 10,
                            margin: "10px 0px 10px 0px",
                            background: "#FCF9FF",
                            border: "none",
                            borderRadius: 10,
                            width: "100%",
                          }}
                        >
                          <Row>
                            <Col
                              span={16}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Tooltip title="Open Link">
                                <a
                                  href={val?.website_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "#72779E", display: "flex" }}
                                >
                                  <LinkOutlined
                                    style={{ marginRight: 10, fontSize: 14 }}
                                  />
                                  <img
                                    src={getLogoUrl(val?.website_link)}
                                    alt="Website Logo"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: 8,
                                      borderRadius: 4,
                                      objectFit: "contain",
                                    }}
                                  />
                                  <Typography>{val?.website_link}</Typography>
                                </a>
                              </Tooltip>
                            </Col>
                            <Col
                              span={8}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                              }}
                            >
                              <Space>
                                <Tooltip title="Address">
                                  <Button
                                    icon={
                                      <GrLocation
                                        style={{
                                          color: "#000",
                                          cursor: "pointer",
                                          fontSize: 16,
                                        }}
                                      />
                                    }
                                  ></Button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <Button
                                    icon={
                                      <DeleteOutlined
                                        onClick={() => handleRemoveValue(index)}
                                        style={{
                                          color: "#ff4d4f",
                                          cursor: "pointer",
                                          fontSize: 14,
                                        }}
                                      />
                                    }
                                  ></Button>
                                </Tooltip>
                              </Space>
                            </Col>
                          </Row>
                          <Row
                            gutter={[10, 0]}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: 10,
                            }}
                          >
                            <Col span={19}>
                              <Select
                                style={{ width: "100%" }}
                                placeholder="Please select address"
                                onChange={handleAddressSelect}
                              >
                                {crearteNewClinicModalForm
                                  ?.getFieldsValue()
                                  ?.clinic_addresses?.map((address, index) => (
                                    <Option
                                      key={index}
                                      value={
                                        address?.clinic_address_state +
                                        "," +
                                        address?.clinic_address_zip_code +
                                        "," +
                                        address?.clinic_address_city +
                                        "," +
                                        address?.clinic_address_street +
                                        "," +
                                        address?.clinic_address_country
                                      }
                                    >
                                      {address?.clinic_address_state +
                                        "," +
                                        address?.clinic_address_zip_code +
                                        "," +
                                        address?.clinic_address_city +
                                        "," +
                                        address?.clinic_address_street +
                                        "," +
                                        address?.clinic_address_country}
                                    </Option>
                                  ))}
                              </Select>
                            </Col>
                            <Col
                              span={4}
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <Button
                                type="link"
                                onClick={() => addAddressToList(index)}
                                disabled={!selectedAddress}
                              >
                                + Add Address
                              </Button>
                            </Col>
                          </Row>
                          <List
                            style={{ marginTop: 10 }}
                            dataSource={val?.addressList}
                            renderItem={(address, addressIndex) => (
                              <List.Item
                                key={addressIndex}
                                actions={[
                                  <Popconfirm
                                    title="Are you sure to delete this address?"
                                    onConfirm={() =>
                                      removeAddress(index, addressIndex)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button type="link" danger>
                                      Remove
                                    </Button>
                                  </Popconfirm>,
                                ]}
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: 10,
                                  }}
                                >
                                  <Space
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <GrLocation
                                      style={{
                                        color: "#000",
                                        cursor: "pointer",
                                        fontSize: 14,
                                      }}
                                    />
                                    <Typography>
                                      {address?.address || "-"}
                                    </Typography>
                                  </Space>
                                </Row>
                              </List.Item>
                            )}
                          />
                        </Tag>
                      ))}
                    </Col>
                  </Row>
                </div>
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

export const VieworEditClinicDetailsModal = ({
  isVieworEditClinicDetailsModal,
  setisVieworEditClinicDetailsModal,
  openModeClinicDetails,
  selectedClinicDetails,
  setselectedClinicDetails,
  openNotificationWithIcon,
  buttonLoader,
  setbuttonLoader,
  getAllClinicDetails,
  setopenModeClinicDetails,
  setvisibleDeleteClinicModalToConfirm,
  setseletedwebsitefordelete,
  setwebsitedeleteConfirmation,
  directLogin,
  directLoginLoader,
}) => {
  const [VieworEditClinicDetailsModalForm] = Form.useForm();
  const [services, setservices] = useState([]);
  const [currentValue, setCurrentValue] = useState("");
  const [websiteList, setwebsiteList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cliniAddressList, setcliniAddressList] = useState([]);
  const [allAddressOptionList, setallAddressOptionList] = useState([]);
  const [clinicLogo, setclinicLogo] = useState();
  const [clinicFavicon, setclinicFavicon] = useState();

  const [mondayFrom, setMondayFrom] = useState();
  const [mondayTo, setMondayTo] = useState();
  const [mondayClosed, setMondayClosed] = useState(true);

  const [tuesdayFrom, setTuesdayFrom] = useState();
  const [tuesdayTo, setTuesdayTo] = useState();
  const [tuesdayClosed, setTuesdayClosed] = useState(true);

  const [wednesdayFrom, setWednesdayFrom] = useState();
  const [wednesdayTo, setWednesdayTo] = useState();
  const [wednesdayClosed, setWednesdayClosed] = useState(true);

  const [thursdayFrom, setThursdayFrom] = useState();
  const [thursdayTo, setThursdayTo] = useState();
  const [thursdayClosed, setThursdayClosed] = useState(true);

  const [fridayFrom, setFridayFrom] = useState();
  const [fridayTo, setFridayTo] = useState();
  const [fridayClosed, setFridayClosed] = useState(true);

  const [saturdayFrom, setSaturdayFrom] = useState();
  const [saturdayTo, setSaturdayTo] = useState();
  const [saturdayClosed, setSaturdayClosed] = useState(false);

  const [sundayFrom, setSundayFrom] = useState();
  const [sundayTo, setSundayTo] = useState();
  const [sundayClosed, setSundayClosed] = useState(false);

  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const items = [
    {
      key: "1",
      label: <Typography>Manage Team</Typography>,
      icon: <RiTeamLine style={{ fontSize: 14 }} />,
      onClick: () => {
        console.log("Manage");
      },
    },
    {
      key: "2",
      label: <Typography>Delete</Typography>,
      icon: <DeleteOutlined style={{ fontSize: 14 }} />,
      onClick: () => {
        setvisibleDeleteClinicModalToConfirm(true);
      },
    },
  ];

  const serviceList = [
    "Full Arch",
    "Dental Implants",
    "General Dentistry",
    "Othodontics",
    "Cosmetic Dentistry",
    "Oral Surgery",
    "Pediatric Dentistry",
  ];

  const clinicSizeOption = [
    {
      value: "1-5 dentists",
      lable: "1-5 dentists",
    },
    {
      value: "6-10 dentists",
      lable: "6-10 dentists",
    },
    {
      value: "11-15 dentists",
      lable: "11-15 dentists",
    },
    {
      value: "16-20 dentists",
      lable: "16-20 dentists",
    },
    {
      value: "21-25 dentists",
      lable: "21-25 dentists",
    },
    {
      value: "25+ dentists",
      lable: "25+ dentists",
    },
  ];

  const patientsAveragePerWeekOption = [
    {
      value: "Less Than 50",
      lable: "Less Than 50",
    },
    {
      value: "51-100",
      lable: "51-100",
    },
    {
      value: "101-150",
      lable: "101-150",
    },
    {
      value: "More Than 150",
      lable: "More Than 150",
    },
  ];

  const handleAddressSelect = (e, addressdetils) => {
    setSelectedAddress({
      clinic_address_id: addressdetils?.data?.id,
      clinic_id: addressdetils?.data?.clinic_id,
      address: addressdetils?.value,
    });
  };

  const removeAddress = (websiteIndex, addressIndex) => {
    setwebsiteList((prevList) =>
      prevList.map((website, i) =>
        i === websiteIndex
          ? {
              ...website,
              websiteaddress: website.websiteaddress.filter(
                (_, j) => j !== addressIndex
              ),
            }
          : website
      )
    );
  };

  const addAddressToList = (websiteIndex) => {
    if (!selectedAddress || !selectedAddress.address) return;

    const addressSplit = selectedAddress.address.split(",");
    if (addressSplit.length < 5) return;

    const newAddress = {
      ...selectedAddress,
      clinic_address_state: addressSplit[0],
      clinic_address_zip_code: addressSplit[1],
      clinic_address_city: addressSplit[2],
      clinic_address_street: addressSplit[3],
      clinic_address_country: addressSplit[4],
    };

    setwebsiteList((prevWebsiteList) => {
      return prevWebsiteList.map((website, index) => {
        if (index === websiteIndex) {
          const isDuplicate = website?.websiteaddress?.some(
            (addr) => addr?.clinic_address_id === newAddress?.clinic_address_id
          );

          // Only add the address if no duplicate is found
          if (!isDuplicate) {
            return {
              ...website,
              websiteaddress: [
                ...(website?.websiteaddress || []),
                { address: newAddress },
              ],
            };
          }
        }
        return website;
      });
    });
  };

  const handleKeyPress = (e) => {
    const trimmedValue = currentValue?.trim();

    if (!trimmedValue) return;

    if (isValidUrl(trimmedValue)) {
      const url = new URL(trimmedValue);

      let temp = {
        clinic_id: selectedClinicDetails?.id,
        website_url: trimmedValue,
        website_user_name: url.hostname?.split(".")?.[0],
        websiteaddress: [],
      };

      const isDuplicate = websiteList.some(
        (item) => item?.website_url === trimmedValue
      );

      if (!isDuplicate) {
        setwebsiteList((prevList) => [...prevList, temp]);
      } else {
        openNotificationWithIcon(
          "error",
          "Clinic",
          "Duplicate URL is not allowed"
        );
      }

      setCurrentValue("");
    } else {
      openNotificationWithIcon("error", "Clinic", "Please enter a valid URL");
    }
  };

  const addServices = (val) => {
    if (services?.includes(val?.trim())) {
      setservices(services?.filter((service) => service !== val));
    } else {
      setservices([...services, val]);
    }
  };

  const CustomButton = ({ service, services, onClick }) => {
    const isSelected = services?.includes(service);

    return (
      <Button
        className="custom-btn"
        style={{
          color: isSelected ? "#3900DB" : "",
          borderColor: isSelected ? "#3900DB" : "",
          background: isSelected ? "#ECEEFF" : "",
        }}
        onClick={onClick}
      >
        {isSelected ? <PiCheckBold /> : ""}
        {service}
      </Button>
    );
  };

  const isValidUrl = (value) => {
    try {
      // Attempt to create a new URL object
      new URL(value);
      return true; // If no error is thrown, the URL is valid
    } catch (_) {
      return false; // If an error is thrown, the URL is invalid
    }
  };

  const getLogoUrl = (url) => {
    try {
      // Validate if the input is a valid URL
      const domain = new URL(url)?.hostname;
      return `https://www.google.com/s2/favicons?domain=${domain}&sz=64`;
    } catch (error) {
      console.error("Invalid URL:", error.message);
      return null; // Return null or a fallback URL in case of an error
    }
  };

  const handleRemoveValue = (index) => {
    let temweb = selectedClinicDetails?.websites?.[index];
    setwebsitedeleteConfirmation(true);
    setseletedwebsitefordelete(temweb);
  };

  const handleClinicLogoChange = async (file) => {
    const maxSizeInMB = 1;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    try {
      let fileToUpload = file;

      if (file.size > maxSizeInBytes) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };

        fileToUpload = await imageCompression(file, options);
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setclinicLogo(e.target.result);
      };
      reader.readAsDataURL(fileToUpload);
    } catch (error) {
      console.error("Image compression failed:", error);
      alert("Image compression failed. Please try again with a smaller file.");
    }
  };

  const handleClinicLogoDelete = () => {
    setclinicLogo();
  };

  const handleClinicFaviconChange = async (file) => {
    const maxSizeInMB = 1;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    try {
      let fileToUpload = file;

      if (file.size > maxSizeInBytes) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };

        fileToUpload = await imageCompression(file, options);
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setclinicFavicon(e.target.result);
      };
      reader.readAsDataURL(fileToUpload);
    } catch (error) {
      console.error("Image compression failed:", error);
      alert("Image compression failed. Please try again with a smaller file.");
    }
  };

  const handleFaviconDelete = () => {
    setclinicFavicon();
  };

  const getAllClinicAddress = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (selectedClinicDetails?.id) {
        await axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/getAllClinicAddress/${selectedClinicDetails?.id}`,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setcliniAddressList(res?.data);
            const tempallAddressOptionList = res?.data?.map((address) => {
              return {
                value:
                  address?.clinic_address_state +
                  "," +
                  address?.clinic_address_zip_code +
                  "," +
                  address?.clinic_address_city +
                  "," +
                  address?.clinic_address_street +
                  "," +
                  address?.clinic_address_country,
                label:
                  address?.clinic_address_state +
                  "," +
                  address?.clinic_address_zip_code +
                  "," +
                  address?.clinic_address_city +
                  "," +
                  address?.clinic_address_street +
                  "," +
                  address?.clinic_address_country,
                data: address,
              };
            });

            setallAddressOptionList(tempallAddressOptionList);

            VieworEditClinicDetailsModalForm?.setFieldValue(
              "clinic_addresses",
              res?.data
            );
          })
          .catch((err) => {
            console.log(err?.response?.data?.message);
          });
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    setwebsiteList(
      selectedClinicDetails?.websites ? selectedClinicDetails?.websites : []
    );
    setMondayFrom(
      selectedClinicDetails?.monday_from
        ? selectedClinicDetails?.monday_from
        : ""
    );
    setMondayTo(
      selectedClinicDetails?.monday_to ? selectedClinicDetails?.monday_to : ""
    );
    setMondayClosed(
      selectedClinicDetails?.monday_closed === "true" ? true : false
    );
    setTuesdayFrom(
      selectedClinicDetails?.tuesday_from
        ? selectedClinicDetails?.tuesday_from
        : ""
    );
    setTuesdayTo(
      selectedClinicDetails?.tuesday_to ? selectedClinicDetails?.tuesday_to : ""
    );
    setTuesdayClosed(
      selectedClinicDetails?.tuesday_closed === "true" ? true : false
    );
    setWednesdayFrom(
      selectedClinicDetails?.wednesday_from
        ? selectedClinicDetails?.wednesday_from
        : ""
    );
    setWednesdayTo(
      selectedClinicDetails?.wednesday_to
        ? selectedClinicDetails?.wednesday_to
        : ""
    );
    setWednesdayClosed(
      selectedClinicDetails?.wednesday_closed === "true" ? true : false
    );
    setThursdayFrom(
      selectedClinicDetails?.thursday_from
        ? selectedClinicDetails?.thursday_from
        : ""
    );
    setThursdayTo(
      selectedClinicDetails?.thursday_to
        ? selectedClinicDetails?.thursday_to
        : ""
    );
    setThursdayClosed(
      selectedClinicDetails?.thursday_closed === "true" ? true : false
    );
    setFridayFrom(
      selectedClinicDetails?.friday_from
        ? selectedClinicDetails?.friday_from
        : ""
    );
    setFridayTo(
      selectedClinicDetails?.friday_to ? selectedClinicDetails?.friday_to : ""
    );
    setFridayClosed(
      selectedClinicDetails?.friday_closed === "true" ? true : false
    );
    setSaturdayFrom(
      selectedClinicDetails?.saturday_from
        ? selectedClinicDetails?.saturday_from
        : ""
    );
    setSaturdayTo(
      selectedClinicDetails?.saturday_to
        ? selectedClinicDetails?.saturday_to
        : ""
    );
    setSaturdayClosed(
      selectedClinicDetails?.saturday_closed === "true" ? true : false
    );
    setSundayFrom(
      selectedClinicDetails?.sunday_from
        ? selectedClinicDetails?.sunday_from
        : ""
    );
    setSundayTo(
      selectedClinicDetails?.sunday_to ? selectedClinicDetails?.sunday_to : ""
    );
    setSundayClosed(
      selectedClinicDetails?.sunday_closed === "true" ? true : false
    );

    if (
      selectedClinicDetails?.owner?.password &&
      selectedClinicDetails?.owner?.iv_encrypted_password
    ) {
      VieworEditClinicDetailsModalForm?.setFieldValue(
        "password",
        decryptPassword(
          selectedClinicDetails?.owner?.password,
          selectedClinicDetails?.owner?.iv_encrypted_password
        )
      );
    }

    VieworEditClinicDetailsModalForm?.setFieldValue(
      "dentist_full_name",
      selectedClinicDetails?.owner?.dentist_full_name
    );
    VieworEditClinicDetailsModalForm?.setFieldValue(
      "clinic_phone_number",
      selectedClinicDetails?.clinic_phone_number
    );
    VieworEditClinicDetailsModalForm?.setFieldValue(
      "clinic_dialer_number",
      selectedClinicDetails?.clinic_dialer_number
    );

    VieworEditClinicDetailsModalForm?.setFieldValue(
      "clinic_name",
      selectedClinicDetails?.clinic_name
    );

    VieworEditClinicDetailsModalForm?.setFieldValue(
      "smtp_host",
      selectedClinicDetails?.smtp_host
    );
    VieworEditClinicDetailsModalForm?.setFieldValue(
      "smtp_email",
      selectedClinicDetails?.smtp_email
    );
    VieworEditClinicDetailsModalForm?.setFieldValue(
      "smtp_password",
      selectedClinicDetails?.smtp_password
    );

    VieworEditClinicDetailsModalForm?.setFieldValue(
      "clinic_name",
      selectedClinicDetails?.clinic_name
    );
    VieworEditClinicDetailsModalForm?.setFieldValue(
      "clinic_name",
      selectedClinicDetails?.clinic_name
    );

    VieworEditClinicDetailsModalForm?.setFieldValue(
      "email",
      selectedClinicDetails?.owner?.email
    );
    setclinicLogo(selectedClinicDetails?.clinic_logo);
    setclinicFavicon(selectedClinicDetails?.clinic_favicon);

    VieworEditClinicDetailsModalForm?.setFieldValue(
      "patients_average_per_week",
      selectedClinicDetails?.patients_average_per_week
    );

    VieworEditClinicDetailsModalForm?.setFieldValue(
      "in_house_arch_lab_yn",
      selectedClinicDetails?.in_house_arch_lab_yn
    );
    VieworEditClinicDetailsModalForm?.setFieldValue(
      "arch_digital_workflow_yn",
      selectedClinicDetails?.arch_digital_workflow_yn
    );
    VieworEditClinicDetailsModalForm?.setFieldValue(
      "clinic_size",
      selectedClinicDetails?.clinic_size
    );
    setservices(
      selectedClinicDetails?.services_frequently
        ? selectedClinicDetails?.services_frequently?.split(",")
        : []
    );

    getAllClinicAddress();
  }, [selectedClinicDetails]);

  const handleUpdateClinicDetails = async (values) => {
    setbuttonLoader(true);

    let data = {
      id: selectedClinicDetails?.id,
      dentist_full_name: values?.dentist_full_name || undefined,
      clinic_name: values?.clinic_name || undefined,
      clinic_phone_number: values?.clinic_phone_number?.startsWith("+")
        ? values.clinic_phone_number
        : `+${values?.clinic_phone_number}`,

      email: values?.email || undefined,
      password: values?.password || undefined,
      clinic_logo: clinicLogo || undefined,
      clinic_favicon: clinicFavicon || undefined,

      clinic_addresses: values?.clinic_addresses,

      // clinic_address_state: values?.clinic_address_state || undefined,
      // clinic_address_zip_code: values?.clinic_address_zip_code || undefined,
      // clinic_address_city: values?.clinic_address_city || undefined,
      // clinic_address_street: values?.clinic_address_street || undefined,
      // clinic_address_country: values?.clinic_address_country || undefined,

      smtp_host: values?.smtp_host || undefined,
      smtp_email: values?.smtp_email || undefined,
      smtp_password: values?.smtp_password || undefined,

      monday_from: mondayFrom ? mondayFrom : undefined,
      monday_to: mondayTo ? mondayTo : undefined,
      monday_closed: mondayClosed,
      tuesday_from: tuesdayFrom ? tuesdayFrom : undefined,
      tuesday_to: tuesdayTo ? tuesdayTo : undefined,
      tuesday_closed: tuesdayClosed,
      wednesday_from: wednesdayFrom ? wednesdayFrom : undefined,
      wednesday_to: wednesdayTo ? wednesdayTo : undefined,
      wednesday_closed: wednesdayClosed,
      thursday_from: thursdayFrom ? thursdayFrom : undefined,
      thursday_to: thursdayTo ? thursdayTo : undefined,
      thursday_closed: thursdayClosed,
      friday_from: fridayFrom ? fridayFrom : undefined,
      friday_to: fridayTo ? fridayTo : undefined,
      friday_closed: fridayClosed,
      saturday_from: saturdayFrom ? saturdayFrom : undefined,
      saturday_to: saturdayTo ? saturdayTo : undefined,
      saturday_closed: saturdayClosed,
      sunday_from: sundayFrom ? sundayFrom : undefined,
      sunday_to: sundayTo ? sundayTo : undefined,
      sunday_closed: sundayClosed,

      clinic_size: values?.clinic_size || undefined,

      patients_average_per_week: values?.patients_average_per_week || undefined,
      in_house_arch_lab_yn: values?.in_house_arch_lab_yn || undefined,
      arch_digital_workflow_yn: values?.arch_digital_workflow_yn || undefined,
      services_frequently: services?.toString() || undefined,

      clinic_dialer_number: values?.clinic_dialer_number?.startsWith("+")
        ? values.clinic_dialer_number
        : `+${values?.clinic_dialer_number}`,

      clinic_website: websiteList || undefined,
    };

    const token = localStorage.getItem("authToken");
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/updateClinic`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Clinic updated successfully"
        );
        setbuttonLoader(false);
        setisVieworEditClinicDetailsModal(false);
        getAllClinicDetails();
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        setbuttonLoader(false);
        openNotificationWithIcon(
          "error",
          "Error",
          err?.response?.data?.message || err?.message
        );
      });
  };

  return (
    <Modal
      style={{ top: 20 }}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ marginLeft: 10 }}>
            {openModeClinicDetails === "view"
              ? "View Clinic Details"
              : "Update Clinic Details"}
          </Typography>

          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              setselectedClinicDetails();
              setisVieworEditClinicDetailsModal(false);
            }}
          ></Button>
        </div>
      }
      visible={isVieworEditClinicDetailsModal}
      footer={
        <>
          {openModeClinicDetails === "view" ? (
            []
          ) : (
            <>
              <Divider style={{ margin: 0 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 10,
                }}
              >
                <Space>
                  <Button
                    onClick={() => {
                      VieworEditClinicDetailsModalForm?.resetFields();
                      setselectedClinicDetails();
                      setisVieworEditClinicDetailsModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="custom-primary-button"
                    loading={buttonLoader}
                    onClick={() => {
                      VieworEditClinicDetailsModalForm?.submit();
                    }}
                  >
                    Update
                  </Button>
                </Space>
              </div>
            </>
          )}
        </>
      }
      closable={false}
      width={800}
      className="custom-modal"
    >
      <Form
        name="crearteNewClinicModalForm"
        form={VieworEditClinicDetailsModalForm}
        initialValues={{ remember: true }}
        onFinish={handleUpdateClinicDetails}
        layout="vertical"
        style={{ height: "73vh", overflow: "auto" }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <Space>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                shape="square"
                size={40}
                src={selectedClinicDetails?.clinic_favicon}
              ></Avatar>
            </div>
            <div style={{ display: "grid", alignItems: "center" }}>
              <Typography type="secondary">
                {selectedClinicDetails?.clinic_name}
              </Typography>
              <Space>
                <Typography className="custom-text1">
                  {selectedClinicDetails?.websites?.length || 0} Websites
                </Typography>
                <Typography className="custom-text1">
                  {selectedClinicDetails?.userClinicRoles?.length || 0} Users
                </Typography>
              </Space>
            </div>
          </Space>
          <Space>
            <Tag
              style={{
                width: "100%",
                margin: "3px 0px",
                background: "#DBEFCF",
                border: "none",
              }}
            >
              {selectedClinicDetails?.total_leads} Leads this month
            </Tag>
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                directLogin(selectedClinicDetails?.owner?.email);
              }}
              loading={directLoginLoader}
            >
              View Dashboard
            </Button>
            <Button
              icon={<AiOutlineEdit />}
              onClick={() => {
                if (openModeClinicDetails === "view") {
                  setopenModeClinicDetails("edit");
                  getAllClinicAddress();
                } else {
                  setopenModeClinicDetails("view");
                }
              }}
            />
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <Button icon={<BsThreeDots />} onClick={() => {}} />
            </Dropdown>
          </Space>
        </Row>

        <Tabs defaultActiveKey="1" style={{ padding: 10 }}>
          <TabPane tab="Account" key="1" style={{ padding: 10 }}>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={12}>
                <Typography className="custom-text1">
                  Dentist Full Name
                </Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.owner?.dentist_full_name}
                  </Typography>
                ) : (
                  <Form.Item
                    name="dentist_full_name"
                    label={
                      <Typography className="custom-text1">Name</Typography>
                    }
                  >
                    <Input
                      placeholder="Please enter dentist full name"
                      className="custom-text1"
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={12}>
                <Typography className="custom-text1">Clinic Name</Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>{selectedClinicDetails?.clinic_name}</Typography>
                ) : (
                  <Form.Item
                    name="clinic_name"
                    label={
                      <Typography className="custom-text1">Name</Typography>
                    }
                  >
                    <Input
                      className="custom-text1"
                      placeholder="Please enter clinic name"
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={12}>
                <Typography className="custom-text1">
                  Clinic Phone Number
                </Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.clinic_phone_number}
                  </Typography>
                ) : (
                  <Form.Item
                    label={
                      <Typography className="custom-text1">
                        Clinic Phone Number
                      </Typography>
                    }
                    required
                  >
                    <Form.Item
                      name="clinic_phone_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number!",
                        },
                      ]}
                      getValueFromEvent={(value) => value}
                    >
                      <PhoneInput country="us" inputStyle={{ width: "100%" }} />
                    </Form.Item>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={12}>
                <Typography className="custom-text1">Clinic Email</Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>{selectedClinicDetails?.owner?.email}</Typography>
                ) : (
                  <Form.Item
                    name="email"
                    label={
                      <Typography className="custom-text1">Email</Typography>
                    }
                  >
                    <Input
                      className="custom-text1"
                      placeholder="Please enter email"
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={12}>
                <Typography className="custom-text1">
                  Clinic Password
                </Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Form.Item name="password">
                    <Input.Password
                      className="custom-text1"
                      disabled
                      style={{ border: "none", boxShadow: "none", width: 150 }}
                      placeholder="Please enter password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="password"
                    label={<Typography>Password</Typography>}
                  >
                    <Input.Password
                      className="custom-text1"
                      placeholder="Please enter password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={12}>
                <Typography className="custom-text1">Clinic Logo</Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                <Form.Item name="clinic_logo">
                  <Space>
                    {clinicLogo ? (
                      <Image
                        size={50}
                        preview={false}
                        style={{ width: 200, height: 80, objectFit: "contain" }}
                        src={clinicLogo}
                      />
                    ) : (
                      ""
                    )}
                    {!clinicLogo ? (
                      <Upload
                        accept="image/*"
                        showUploadList={false}
                        beforeUpload={(file) => {
                          handleClinicLogoChange(file);
                          return false;
                        }}
                        style={{ width: "100%" }}
                      >
                        <Button icon={<BiUpload />} style={{ marginTop: 8 }}>
                          Upload Clinic Logo
                        </Button>
                      </Upload>
                    ) : (
                      ""
                    )}
                    {clinicLogo && openModeClinicDetails !== "view" ? (
                      <Button
                        type="link"
                        danger
                        onClick={handleClinicLogoDelete}
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={12}>
                <Typography className="custom-text1">Clinic Favicon</Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                <Form.Item name="clinic_favicon">
                  <Space>
                    {clinicFavicon ? (
                      <Avatar size={50} src={clinicFavicon} shape="square" />
                    ) : (
                      ""
                    )}

                    {!clinicFavicon ? (
                      <Upload
                        accept="image/*"
                        showUploadList={false}
                        beforeUpload={(file) => {
                          handleClinicFaviconChange(file);
                          return false; // Prevent auto-upload
                        }}
                      >
                        <Button icon={<BiUpload />} style={{ marginTop: 8 }}>
                          Upload Clinic Favicon
                        </Button>
                      </Upload>
                    ) : (
                      ""
                    )}
                    {clinicFavicon && openModeClinicDetails !== "view" ? (
                      <Button type="link" danger onClick={handleFaviconDelete}>
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Clinic Details" key="2" style={{ padding: 10 }}>
            <Row style={{ padding: "10px 0px 10px 0px" }}>
              <Col span={8}>
                <Typography className="custom-text1">Clinic Address</Typography>
              </Col>
              <Col
                span={16}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {cliniAddressList?.map((address, index) => {
                      return (
                        <>
                          <Typography>#{index + 1} Address</Typography>
                          <Typography>
                            {address?.clinic_address_state},
                            {address?.clinic_address_zip_code},
                            {address?.clinic_address_city},
                            {address?.clinic_address_street},
                            {address?.clinic_address_country}
                          </Typography>
                        </>
                      );
                    })}
                  </Typography>
                ) : (
                  <Form.List name="clinic_addresses">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <>
                            <Space
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>#{key + 1} Address</Typography>

                              {fields.length > 1 && (
                                <Col span={12}>
                                  <Button
                                    type="dashed"
                                    danger
                                    onClick={() => remove(name)}
                                    icon={<MinusCircleOutlined />}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              )}
                            </Space>
                            <Row
                              key={key}
                              gutter={[10, 0]}
                              style={{ marginBottom: 10 }}
                            >
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "clinic_address_state"]}
                                  label={
                                    <Typography className="custom-text1">
                                      State
                                    </Typography>
                                  }
                                >
                                  <Input
                                    placeholder="Enter state"
                                    className="custom-text1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "clinic_address_zip_code"]}
                                  label={
                                    <Typography className="custom-text1">
                                      ZIP Code
                                    </Typography>
                                  }
                                >
                                  <Input
                                    placeholder="Enter ZIP Code"
                                    className="custom-text1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "clinic_address_city"]}
                                  label={
                                    <Typography className="custom-text1">
                                      City
                                    </Typography>
                                  }
                                >
                                  <Input
                                    placeholder="Enter clinic city"
                                    className="custom-text1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "clinic_address_street"]}
                                  label={
                                    <Typography className="custom-text1">
                                      Street
                                    </Typography>
                                  }
                                >
                                  <Input
                                    placeholder="Enter clinic street"
                                    className="custom-text1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "clinic_address_country"]}
                                  label={
                                    <Typography className="custom-text1">
                                      Country
                                    </Typography>
                                  }
                                >
                                  <Input
                                    placeholder="Enter country"
                                    className="custom-text1"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add Address
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={8}>
                <Typography className="custom-text1">
                  {" "}
                  Consulting Hours
                </Typography>
              </Col>
              <Col
                span={16}
                style={{
                  display: openModeClinicDetails === "view" ? "grid" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography>
                        Monday{" "}
                        {mondayClosed ? (
                          <>
                            | {mondayFrom} to {mondayTo}
                          </>
                        ) : (
                          <span>| Closed</span>
                        )}
                      </Typography>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography>
                        Tuesday{" "}
                        {tuesdayClosed ? (
                          <>
                            | {tuesdayFrom} to {tuesdayTo}
                          </>
                        ) : (
                          <span>| Closed</span>
                        )}
                      </Typography>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography>
                        Wednesday{" "}
                        {wednesdayClosed ? (
                          <>
                            | {wednesdayFrom} to {wednesdayTo}
                          </>
                        ) : (
                          <span>| Closed</span>
                        )}
                      </Typography>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography>
                        Thursday{" "}
                        {thursdayClosed ? (
                          <>
                            | {thursdayFrom} to {thursdayTo}
                          </>
                        ) : (
                          <span>| Closed</span>
                        )}
                      </Typography>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography>
                        Friday{" "}
                        {fridayClosed ? (
                          <>
                            | {fridayFrom} to {fridayTo}
                          </>
                        ) : (
                          <span>| Closed</span>
                        )}
                      </Typography>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography>
                        Saturday{" "}
                        {saturdayClosed ? (
                          <>
                            | {saturdayFrom} to {saturdayTo}
                          </>
                        ) : (
                          <span>| Closed</span>
                        )}
                      </Typography>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Typography>
                        Sunday{" "}
                        {sundayClosed ? (
                          <>
                            | {sundayFrom} to {sundayTo}
                          </>
                        ) : (
                          <span>| Closed</span>
                        )}
                      </Typography>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row
                      style={{
                        display: "flex",
                        justifyItems: "center",
                        justifyContent: "space-between",
                        padding: "5px 0px",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Switch
                            checked={mondayClosed}
                            onChange={(e) => {
                              setMondayClosed(e);
                            }}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Monday
                          </Typography>
                        </div>
                        {mondayClosed ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <>
                              <Typography style={{ marginRight: "5px" }}>
                                From
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  mondayFrom
                                    ? dayjs(mondayFrom, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setMondayFrom(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setMondayFrom(null);
                                  }
                                }}
                              />

                              <Typography style={{ margin: "0px 5px" }}>
                                To
                              </Typography>
                              <TimePicker
                                value={
                                  mondayTo ? dayjs(mondayTo, "hh:mm A") : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setMondayTo(dayjs(time).format("hh:mm A"));
                                  } else {
                                    setMondayTo(null);
                                  }
                                }}
                                format={"hh:mm A"}
                              />
                            </>
                          </div>
                        ) : (
                          <Button
                            disabled
                            icon={<RiZzzFill />}
                            style={{ width: "100%", maxWidth: 250 }}
                          >
                            Closed
                          </Button>
                        )}
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          margin: "10px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Switch
                            checked={tuesdayClosed}
                            onChange={(e) => {
                              setTuesdayClosed(e);
                            }}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Tuesday
                          </Typography>
                        </div>

                        {tuesdayClosed ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography style={{ marginRight: "5px" }}>
                                From
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  tuesdayFrom
                                    ? dayjs(tuesdayFrom, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setTuesdayFrom(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setTuesdayFrom(null);
                                  }
                                }}
                              />
                              <Typography style={{ margin: "0px 5px" }}>
                                To
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  tuesdayTo ? dayjs(tuesdayTo, "hh:mm A") : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setTuesdayTo(dayjs(time).format("hh:mm A"));
                                  } else {
                                    setTuesdayTo(null);
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled
                              icon={<RiZzzFill />}
                              style={{ width: "100%", maxWidth: 250 }}
                            >
                              Closed
                            </Button>
                          </>
                        )}
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          margin: "10px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Switch
                            checked={wednesdayClosed}
                            onChange={(e) => {
                              setWednesdayClosed(e);
                            }}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Wednesday
                          </Typography>
                        </div>
                        {wednesdayClosed ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography style={{ marginRight: "5px" }}>
                                From
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  wednesdayFrom
                                    ? dayjs(wednesdayFrom, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setWednesdayFrom(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setWednesdayFrom(null);
                                  }
                                }}
                              />
                              <Typography style={{ margin: "0px 5px" }}>
                                To
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  wednesdayTo
                                    ? dayjs(wednesdayTo, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setWednesdayTo(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setWednesdayTo(null);
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled
                              icon={<RiZzzFill />}
                              style={{ width: "100%", maxWidth: 250 }}
                            >
                              Closed
                            </Button>
                          </>
                        )}
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          margin: "10px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Switch
                            checked={thursdayClosed}
                            onChange={(e) => {
                              setThursdayClosed(e);
                            }}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Thursday
                          </Typography>
                        </div>
                        {thursdayClosed ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography style={{ marginRight: "5px" }}>
                                From
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  thursdayFrom
                                    ? dayjs(thursdayFrom, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setThursdayFrom(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setThursdayFrom(null);
                                  }
                                }}
                              />
                              <Typography style={{ margin: "0px 5px" }}>
                                To
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  thursdayTo
                                    ? dayjs(thursdayTo, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setThursdayTo(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setThursdayTo(null);
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled
                              icon={<RiZzzFill />}
                              style={{ width: "100%", maxWidth: 250 }}
                            >
                              Closed
                            </Button>
                          </>
                        )}
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          margin: "10px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Switch
                            checked={fridayClosed}
                            onChange={(e) => {
                              setFridayClosed(e);
                            }}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Friday
                          </Typography>
                        </div>
                        {fridayClosed ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography style={{ marginRight: "5px" }}>
                                From
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  fridayFrom
                                    ? dayjs(fridayFrom, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setFridayFrom(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setFridayFrom(null);
                                  }
                                }}
                              />
                              <Typography style={{ margin: "0px 5px" }}>
                                To
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  fridayTo ? dayjs(fridayTo, "hh:mm A") : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setFridayTo(dayjs(time).format("hh:mm A"));
                                  } else {
                                    setFridayTo(null);
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled
                              icon={<RiZzzFill />}
                              style={{ width: "100%", maxWidth: 250 }}
                            >
                              Closed
                            </Button>
                          </>
                        )}
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          margin: "10px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Switch
                            checked={saturdayClosed}
                            onChange={(e) => {
                              setSaturdayClosed(e);
                            }}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Saturday
                          </Typography>
                        </div>
                        {saturdayClosed ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography style={{ marginRight: "5px" }}>
                                From
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  saturdayFrom
                                    ? dayjs(saturdayFrom, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setSaturdayFrom(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setSaturdayFrom(null);
                                  }
                                }}
                              />
                              <Typography style={{ margin: "0px 5px" }}>
                                To
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  saturdayTo
                                    ? dayjs(saturdayTo, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setSaturdayTo(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setSaturdayTo(null);
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled
                              icon={<RiZzzFill />}
                              style={{ width: "100%", maxWidth: 250 }}
                            >
                              Closed
                            </Button>
                          </>
                        )}
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          margin: "10px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Switch
                            checked={sundayClosed}
                            onChange={(e) => {
                              setSundayClosed(e);
                            }}
                          />
                          <Typography style={{ marginLeft: "10px" }}>
                            Sunday
                          </Typography>
                        </div>
                        {sundayClosed ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography style={{ marginRight: "5px" }}>
                                From
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  sundayFrom
                                    ? dayjs(sundayFrom, "hh:mm A")
                                    : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setSundayFrom(
                                      dayjs(time).format("hh:mm A")
                                    );
                                  } else {
                                    setSundayFrom(null);
                                  }
                                }}
                              />
                              <Typography style={{ margin: "0px 5px" }}>
                                To
                              </Typography>
                              <TimePicker
                                format={"hh:mm A"}
                                value={
                                  sundayTo ? dayjs(sundayTo, "hh:mm A") : null
                                }
                                onChange={(time) => {
                                  if (time) {
                                    setSundayTo(dayjs(time).format("hh:mm A"));
                                  } else {
                                    setSundayTo(null);
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled
                              icon={<RiZzzFill />}
                              style={{ width: "100%", maxWidth: 250 }}
                            >
                              Closed
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={8}>
                <Typography className="custom-text1">Clinic size</Typography>
              </Col>
              <Col
                span={16}
                style={{
                  display: openModeClinicDetails === "view" ? "grid" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.clinic_size || "-"}
                  </Typography>
                ) : (
                  <Form.Item
                    name="clinic_size"
                    label={<Typography>What is clinic size?</Typography>}
                  >
                    <Select
                      showSearch
                      placeholder="Select a clinic size"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={clinicSizeOption}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={8}>
                <Typography className="custom-text1">
                  Average Patients
                </Typography>
              </Col>
              <Col
                span={16}
                style={{
                  display: openModeClinicDetails === "view" ? "grid" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.patients_average_per_week || "-"}
                  </Typography>
                ) : (
                  <Form.Item
                    name="patients_average_per_week"
                    label={
                      <Typography>
                        How many patients do you see on average per week?
                      </Typography>
                    }
                  >
                    <Select
                      showSearch
                      placeholder="Select a person"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={patientsAveragePerWeekOption}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={8}>
                <Typography className="custom-text1">
                  Dental Services
                </Typography>
              </Col>
              <Col
                span={16}
                style={{
                  display: openModeClinicDetails === "view" ? "flex" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.services_frequently || "-"}
                  </Typography>
                ) : (
                  <Form.Item name="services_frequently">
                    {serviceList.map((service) => (
                      <CustomButton
                        key={service}
                        service={service}
                        services={services}
                        onClick={() => addServices(service)}
                      />
                    ))}
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={8}>
                <Typography className="custom-text1">
                  In-house full arch lab?
                </Typography>
              </Col>
              <Col
                span={16}
                style={{
                  display: openModeClinicDetails === "view" ? "grid" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.in_house_arch_lab_yn === "Y"
                      ? "Yes"
                      : "No"}
                  </Typography>
                ) : (
                  <Form.Item name="in_house_arch_lab_yn">
                    <Radio.Group>
                      <Radio value="Y">Yes</Radio>
                      <Radio value="N">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px 10px 0px",
              }}
            >
              <Col span={8}>
                <Typography className="custom-text1">
                  Full arch digital workflow?
                </Typography>
              </Col>
              <Col
                span={16}
                style={{
                  display: openModeClinicDetails === "view" ? "grid" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.arch_digital_workflow_yn === "Y"
                      ? "Yes"
                      : "No"}
                  </Typography>
                ) : (
                  <Form.Item name="arch_digital_workflow_yn">
                    <Radio.Group>
                      <Radio value="Y" type="primary">
                        Yes
                      </Radio>
                      <Radio value="N">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Dialer" key="3" style={{ padding: 10 }}>
            <Row
              gutter={[20, 20]}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Col span={12}>
                <Typography className="custom-text1">
                  Clinic Dialer Number{" "}
                </Typography>
              </Col>
              <Col
                span={12}
                style={{
                  display: openModeClinicDetails === "view" ? "grid" : "",
                  justifyContent: openModeClinicDetails === "view" ? "end" : "",
                }}
              >
                {openModeClinicDetails === "view" ? (
                  <Typography>
                    {selectedClinicDetails?.clinic_dialer_number || "-"}
                  </Typography>
                ) : (
                  <Form.Item
                    name="clinic_dialer_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number!",
                      },
                    ]}
                    getValueFromEvent={(value) => value}
                  >
                    <PhoneInput country="us" inputStyle={{ width: "100%" }} />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="SMTP Config" key="4" style={{ padding: 10 }}>
            <div style={{ padding: 10 }}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <Col span={12}>
                  <Typography className="custom-text1">SMTP Host</Typography>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: openModeClinicDetails === "view" ? "flex" : "",
                    justifyContent:
                      openModeClinicDetails === "view" ? "end" : "",
                  }}
                >
                  {openModeClinicDetails !== "view" ? (
                    <Form.Item name="smtp_host" noStyle>
                      <Input
                        className="custom-text1"
                        placeholder="Enter smtp host"
                      />
                    </Form.Item>
                  ) : (
                    <Typography>
                      {selectedClinicDetails?.smtp_host || ""}
                    </Typography>
                  )}
                </Col>
              </Row>
              <Divider style={{ margin: 0 }} />
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <Col span={12}>
                  <Typography className="custom-text1">SMTP Email</Typography>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: openModeClinicDetails === "view" ? "flex" : "",
                    justifyContent:
                      openModeClinicDetails === "view" ? "end" : "",
                  }}
                >
                  {openModeClinicDetails !== "view" ? (
                    <Form.Item name="smtp_email" noStyle>
                      <Input
                        className="custom-text1"
                        placeholder="Enter smtp email"
                      />
                    </Form.Item>
                  ) : (
                    <Typography>
                      {selectedClinicDetails?.smtp_email || ""}
                    </Typography>
                  )}
                </Col>
              </Row>
              <Divider style={{ margin: 0 }} />
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <Col span={12}>
                  <Typography className="custom-text1">
                    SMTP Password
                  </Typography>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: openModeClinicDetails === "view" ? "flex" : "",
                    justifyContent:
                      openModeClinicDetails === "view" ? "end" : "",
                  }}
                >
                  {openModeClinicDetails === "view" ? (
                    <Form.Item name="smtp_password">
                      <Input.Password
                        className="custom-text1"
                        disabled
                        style={{
                          border: "none",
                          boxShadow: "none",
                          width: 250,
                        }}
                        placeholder="Please enter password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item name="smtp_password">
                      <Input.Password
                        className="custom-text1"
                        placeholder="Please enter smtp password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Websites" key="5" style={{ padding: 10 }}>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col span={24}>
                <Typography>Clinic Website</Typography>

                {openModeClinicDetails !== "view" ? (
                  <>
                    <Row
                      gutter={[10, 0]}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Col span={21}>
                        <Input
                          className="custom-text1"
                          placeholder="Please enter clinic website"
                          value={currentValue}
                          onChange={handleInputChange}
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={3}>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            handleKeyPress();
                          }}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </Col>
              <Col span={24}>
                {websiteList?.map((val, index) => (
                  <Tag
                    key={index}
                    className="custom-text1"
                    style={{
                      padding: 10,
                      margin: "10px 0px 10px 0px",
                      background: "#FCF9FF",
                      border: "none",
                      borderRadius: 10,
                      width: "100%",
                    }}
                  >
                    <Row>
                      <Col
                        span={16}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Tooltip title="Open Link">
                          <a
                            href={val?.website_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#72779E", display: "flex" }}
                          >
                            <LinkOutlined
                              style={{ marginRight: 10, fontSize: 14 }}
                            />
                            <img
                              src={getLogoUrl(val?.website_url)}
                              alt="Website Logo"
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 8,
                                borderRadius: 4,
                                objectFit: "contain",
                              }}
                            />
                            <Typography>{val?.website_url}</Typography>
                          </a>
                        </Tooltip>
                      </Col>
                      <Col
                        span={8}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Space>
                          <Tooltip title="Address">
                            <Button
                              icon={
                                <GrLocation
                                  style={{
                                    color: "#000",
                                    cursor: "pointer",
                                    fontSize: 16,
                                  }}
                                />
                              }
                            ></Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button
                              icon={
                                <DeleteOutlined
                                  onClick={() => handleRemoveValue(index)}
                                  style={{
                                    color: "#ff4d4f",
                                    cursor: "pointer",
                                    fontSize: 14,
                                  }}
                                />
                              }
                            ></Button>
                          </Tooltip>
                        </Space>
                      </Col>
                    </Row>

                    {openModeClinicDetails !== "view" ? (
                      <Row
                        gutter={[10, 0]}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: 10,
                        }}
                      >
                        <Col span={19}>
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Please select address"
                            onChange={(e, addressdetils) => {
                              handleAddressSelect(e, addressdetils);
                            }}
                            options={allAddressOptionList}
                          />
                        </Col>
                        <Col
                          span={4}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            type="link"
                            onClick={() => addAddressToList(index)}
                            disabled={!selectedAddress}
                          >
                            + Add Address
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {openModeClinicDetails !== "view" ? (
                      <List
                        style={{ marginTop: 10 }}
                        dataSource={val?.websiteaddress}
                        renderItem={(webaddress, addressIndex) => (
                          <List.Item style={{ display: "grid" }}>
                            <Space
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Space
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <GrLocation
                                  style={{
                                    color: "#000",
                                    cursor: "pointer",
                                    fontSize: 14,
                                  }}
                                />
                                <Typography>
                                  {[
                                    webaddress?.address?.clinic_address_state,
                                    webaddress?.address
                                      ?.clinic_address_zip_code,
                                    webaddress?.address?.clinic_address_city,
                                    webaddress?.address?.clinic_address_street,
                                    webaddress?.address?.clinic_address_country,
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                                </Typography>
                              </Space>

                              {/* Remove Button */}
                              <Popconfirm
                                title="Are you sure you want to delete this address?"
                                onConfirm={() =>
                                  removeAddress(index, addressIndex)
                                }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link" danger>
                                  Remove
                                </Button>
                              </Popconfirm>
                            </Space>
                          </List.Item>
                        )}
                      />
                    ) : (
                      <List
                        dataSource={val?.websiteaddress}
                        renderItem={(webaddress, addressIndex) => (
                          <List.Item
                            key={addressIndex}
                            style={{ display: "grid" }}
                          >
                            <Row
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: 10,
                              }}
                            >
                              <Space
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <GrLocation
                                  style={{
                                    color: "#000",
                                    cursor: "pointer",
                                    fontSize: 14,
                                  }}
                                />
                                <Typography>
                                  {[
                                    webaddress?.address?.clinic_address_state,
                                    webaddress?.address
                                      ?.clinic_address_zip_code,
                                    webaddress?.address?.clinic_address_city,
                                    webaddress?.address?.clinic_address_street,
                                    webaddress?.address?.clinic_address_country,
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                                </Typography>
                              </Space>
                            </Row>
                          </List.Item>
                        )}
                      />
                    )}
                  </Tag>
                ))}
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export const DeleteClinicModalToConfirm = ({
  visibleDeleteClinicModalToConfirm,
  setvisibleDeleteClinicModalToConfirm,
  selectedClinicDetails,
  openNotificationWithIcon,
  setisVieworEditClinicDetailsModal,
  getAllClinicDetails,
}) => {
  const [buttonLoader, setbuttonLoader] = useState();

  const handleDeleteClinic = async () => {
    setbuttonLoader(true);
    const token = localStorage.getItem("authToken");
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/clinic/${selectedClinicDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbuttonLoader(false);
        setvisibleDeleteClinicModalToConfirm(false);
        setisVieworEditClinicDetailsModal(false);
        openNotificationWithIcon(
          "success",
          "Success",
          "Clinic deleted successfully"
        );
        getAllClinicDetails();
      })
      .catch((err) => {
        setbuttonLoader(false);
        console.log(err?.response?.data?.message);
        openNotificationWithIcon(
          "error",
          "Error",
          err?.response?.data?.message || err?.message
        );
      });
  };

  return (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ marginLeft: 10 }}>
            🛑 Are you sure you want to delete this clinic?
          </Typography>
        </div>
      }
      visible={visibleDeleteClinicModalToConfirm}
      footer={
        <>
          <>
            <Divider style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 10,
              }}
            >
              <Space>
                <Button
                  onClick={() => {
                    setvisibleDeleteClinicModalToConfirm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  loading={buttonLoader}
                  style={{
                    background: "#F41A1A",
                    border: "none",
                  }}
                  onClick={() => {
                    handleDeleteClinic();
                  }}
                >
                  <Typography style={{ color: "#fff" }}>Delete</Typography>
                </Button>
              </Space>
            </div>
          </>
        </>
      }
      closable={false}
      width={400}
      className="custom-modal"
    >
      <Typography style={{ padding: "10px 0px" }}>
        Deleting this clinic will permanently remove all its data, including:
      </Typography>
      <ul style={{ paddingLeft: 15, margin: 0 }}>
        <li>Appointments and schedules.</li>
        <li>Leads and lead-related data.</li>
        <li>Users associated with the clinic will lose access.</li>
      </ul>
    </Modal>
  );
};

export const DeleteWebsite = ({
  websitedeleteConfirmation,
  setwebsitedeleteConfirmation,
  seletedwebsitefordelete,
  buttonLoader,
  setbuttonLoader,
  handleClinicDetails,
  openNotificationWithIcon,
  setisVieworEditClinicDetailsModal,
}) => {
  const handleDeleteWebsite = async () => {
    setbuttonLoader(true);
    const token = localStorage.getItem("authToken");
    let data = {
      clinic_id: seletedwebsitefordelete?.clinic_id,
      website_id: seletedwebsitefordelete?.id,
    };

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/deleteClinicWebsite`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          handleClinicDetails();
          setwebsitedeleteConfirmation(false);
          setisVieworEditClinicDetailsModal(false);
          openNotificationWithIcon(
            "success",
            "Settings",
            "Website deleted successfully"
          );
          setbuttonLoader(false);
        })
        .catch((err) => {
          console.log(err?.response?.data?.message);
          openNotificationWithIcon(
            "error",
            "Settings",
            err?.response?.data?.message || err?.message
          );
        });
    } catch (err) {
      openNotificationWithIcon(
        "error",
        "Settings",
        err?.response?.data?.message || err?.message
      );
    }
    setbuttonLoader(false);
  };

  return (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => setwebsitedeleteConfirmation(false)}
            icon={<IoChevronBackSharp />}
          />
          <Typography style={{ marginLeft: 10 }}>
            Delete Confirmation
          </Typography>
        </div>
      }
      visible={websitedeleteConfirmation}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0px 10px 10px 0px",
          }}
        >
          <Space>
            <Button onClick={() => setwebsitedeleteConfirmation(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                handleDeleteWebsite();
              }}
              loading={buttonLoader}
            >
              Delete
            </Button>
          </Space>
        </div>
      }
      closable={false}
      width={seletedwebsitefordelete?.userWebsiteRoles?.length <= 0 ? 450 : 600}
      className="custom-modal"
    >
      {seletedwebsitefordelete?.userWebsiteRoles?.length <= 0 ? (
        <>
          <Typography style={{ padding: "0 10px" }}>
            Are you sure you want to delete this website?
          </Typography>
        </>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TeamSVG
              color={"#72779E"}
              style={{ width: 15, display: "contents" }}
            />
            <Typography style={{ fontWeight: 600, marginLeft: 5 }}>
              Delete {seletedwebsitefordelete?.website_user_name} Website
            </Typography>
          </div>
          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <Col span={22}>
              <Typography className="custom-text1">
                Are you sure you want to delete this website? This website is
                currently accessible to the following users:
              </Typography>
            </Col>
          </Row>
          <List
            style={{
              height: "40vh",
              overflow: "auto",
              padding: "10px 10px 100px 10px",
            }}
            itemLayout="horizontal"
            dataSource={seletedwebsitefordelete?.userWebsiteRoles}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    item?.user?.profile_p || item?.user?.profile_picture ? (
                      <Avatar
                        src={
                          item?.user?.profile_p || item?.user?.profile_picture
                        }
                        size={40}
                      />
                    ) : (
                      <Avatar
                        size={40}
                        style={{
                          background:
                            item?.user?.avatar_co || item?.user?.avatar_color,
                        }}
                      >
                        {getInitials(
                          item?.user?.dentist_f || item?.user?.dentist_full_name
                        )}
                      </Avatar>
                    )
                  }
                  description={
                    <>
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        <Col span={12}>
                          <Typography>
                            {item?.user?.dentist_f ||
                              item?.user?.dentist_full_name}
                          </Typography>
                          <Typography>{item?.user?.email}</Typography>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Typography>{item?.role?.role_name}</Typography>
                        </Col>
                      </Row>
                    </>
                  }
                />
              </List.Item>
            )}
          />
          <Row
            style={{
              background: "#FFDBDB",
              padding: 10,
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col span={2}>
              <MdOutlineErrorOutline
                style={{ color: "#E21D12", fontSize: 30 }}
              />
            </Col>
            <Col span={22}>
              <Typography style={{ color: "#E21D12" }}>
                Deleting this website will revoke access for all users and
                remove all associated data.
              </Typography>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};
